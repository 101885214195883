<template>
  <div style="background: #f0f2f5">
    <div class="left">
      <a-divider orientation="left">分类</a-divider>
      <a-tree
        :replaceFields="{
          title: 'name',
          key: 'id',
          children: 'children',
        }"
        :treeData="categoryList"
        @select="onTypeSelect"
      />
    </div>
    <div class="right">
      <div class="tableList">
        <div class="search">
          <div class="search-body">
            <a-form-model layout="inline">
              <a-form-model-item label="资讯标题">
                <a-input placeholder="请输入" v-model="search.title" />
              </a-form-model-item>
              <a-form-item label="添加时间">
                <a-range-picker @change="onDateChange" />
              </a-form-item>
              <a-form-model-item>
                <p-button type="primary" @click="searchList" name="搜索" />
              </a-form-model-item>
            </a-form-model>
            <div class="button_group">
              <p-button name="添加" @click="showInfo(0)" type="primary" />
              <!-- <p-button
              name="批量提交"
              :disabled="selectRows.length == 0"
              @click="batchSubmit"
              :roles="['文章-文章添加']"
            /> -->
              <p-button
                name="批量删除"
                :disabled="selectRows.length == 0"
                :type="`danger`"
                @click="batchDelete"
                :roles="['文章-文章删除']"
              />
              <span style="margin-left: 8px">
                <template v-if="selectRows.length > 0">
                  {{ `已选择 ${selectRows.length} 项` }}
                  <a-button type="link" @click="cancelSelect"
                    >取消选择</a-button
                  >
                </template>
              </span>
            </div>
          </div>
        </div>

        <div>
          <a-table
            rowKey="id"
            size="small"
            bordered
            :columns="list.columns"
            :pagination="list.pagination"
            @change="onPageChange"
            :dataSource="list.datas"
            :loading="isloading"
            :rowSelection="{
              type: 'checkbox',
              columnWidth: 40,
              onChange: onRowChange,
              selectedRowKeys: selectRows,
            }"
            :locale="{ emptyText: '暂无数据' }"
            :scroll="{ x: 1000, y: list.tableHeight }"
          >
            <template slot="articleType" slot-scope="articleType">
              <span v-if="articleType == 1">文章</span>
              <span v-if="articleType == 2">视频</span>
              <span v-if="articleType == 3">图集</span>
            </template>
            <template slot="pictureType" slot-scope="pictureType">
              <span v-if="pictureType == 0">无图</span>
              <span v-if="pictureType == 1">单图大图</span>
              <span v-if="pictureType == 2">单图居左</span>
              <span v-if="pictureType == 3">单图居右</span>
              <span v-if="pictureType == 4">三图</span>
            </template>
            <template slot="recommend" slot-scope="recommend">
              <span v-if="recommend == 1">推荐</span>
            </template>
            <!-- <template slot="auditStatus" slot-scope="auditStatus">
              <span v-if="auditStatus == 0">待提交</span>
              <span v-if="auditStatus == 1">待审核</span>
              <span v-if="auditStatus == 2">审核通过</span>
              <span v-if="auditStatus == 3">审核拒绝</span>
            </template> -->
            <template slot="auditStatus" slot-scope="auditStatus">
              <processStatus :processModel="auditStatus" />
            </template>
            <span slot="action" slot-scope="text, record, index">
              <a-button
                type="primary"
                @click="showInfo(record.id, record, index)"
                size="small"
                >{{
                  record.processStatus == null || record.processStatus == 0
                    ? "编辑"
                    : "查看"
                }}</a-button
              >
              <a-popconfirm @confirm="delInfo(record.id, record, index)">
                <div slot="title">是否确认删除此文章？</div>
                <a-icon slot="icon" type="question-circle-o" />
                <a-button
                  name="删除"
                  type="danger"
                  size="small"
                  :roles="['文章-文章删除']"
                  >刪除</a-button
                >
              </a-popconfirm>
              <p-button
                name="评论管理"
                size="small"
                @click="showComment(record.id)"
                type="primary"
                :roles="['评论-评论管理']"
                v-if="suaffArticleList.indexOf(record.categoryId) != -1"
              />
            </span>
          </a-table>
        </div>
      </div>
    </div>
    <a-modal
      title="详情"
      v-model="addedit.visible"
      width="1100px"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
    >
      <NewsArticleInfo @callback="save" :info="addedit.info" />
    </a-modal>

    <a-modal
      title="评论列表"
      v-model="comment.visible"
      width="1200px"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
    >
      <CommentList :guid="comment.id" />
    </a-modal>
  </div>
</template>
<script>
import NewsArticleInfo from "./NewsArticleInfo.vue"; //新增编辑
import CommentList from "./CommentList.vue"; //评论列表
import category from "@/api/cms/category";
import workflowApi from "@/api/workflow";
import newsArticle from "@/api/cms/newsArticle";
const columns = [
  {
    dataIndex: "title",
    align: "left",
    title: "资讯标题",
    ellipsis: true,
  },
  {
    dataIndex: "categoryName",
    align: "center",
    title: "所属分类",
    ellipsis: true,
  },
  {
    dataIndex: "articleType",
    align: "center",
    title: "资讯类型",
    width: 90,
    scopedSlots: { customRender: "articleType" },
  },
  {
    dataIndex: "pictureType",
    align: "center",
    title: "封面图类型",
    width: 90,
    scopedSlots: { customRender: "pictureType" },
  },
  // {
  //   dataIndex: "recommend",
  //   align: "center",
  //   title: "标签",
  //   scopedSlots: { customRender: "recommend" },
  // },
  {
    dataIndex: "publishTime",
    align: "center",
    title: "发布日期",
    width: 90,
  },
  {
    dataIndex: "createTime",
    align: "center",
    title: "添加时间",
  },
  {
    align: "center",
    title: "状态",
    scopedSlots: { customRender: "auditStatus" },
  },
  {
    title: "操作",
    key: "action",
    align: "center",
    width: 210,
    scopedSlots: { customRender: "action" },
  },
];
let _this;
export default {
  name: "NewsAtricleList",
  data() {
    return {
      list: {
        columns, //表头
        datas: [], //数据
        pagination: {
          showQuickJumper: true,
          showSizeChanger: true,
          total: 0,
          current: 1,
          pageSize: 10,
          showTotal: (total) => `共 ${total} 条`,
        },
        tableHeight: window.innerHeight - 330,
      },
      categoryList: [],
      addedit: {
        title: "",
        info: {},
        visible: false,
      },
      comment: {
        visible: false,
        id: null,
      },
      search: {
        categoryId: [],
        title: "",
        publishTimeStart: null,
        publishTimeEnd: null,
      },
      selectRows: [], //选中项编号
      isloading: false,
      suaffArticleList: [],
    };
  },
  components: {
    NewsArticleInfo,
    CommentList,
  },
  mounted() {
    _this = this;
    if (window.innerWidth >= 1600) {
      _this.list.pagination.pageSize = 20;
    }
    window.onresize = () => {
      _this.list.tableHeight = window.innerHeight - 330;
      if (window.innerWidth >= 1600) {
        _this.list.pagination.pageSize = 20;
      } else {
        _this.list.pagination.pageSize = 10;
      }
    };
    _this.getCategoryTree();
    _this.GetList();
  },
  methods: {
    publishArticle(record) {
      var audit = {
        taskKey: record.taskKey,
        taskId: record.taskId,
        businessId: record.id,
        businessType: record.processModelKey,
        auditResult: 1,
      };
      workflowApi.postAuditData(audit).then((res) => {
        if (res.errorCode === this.$commons.RespCode.success) {
          this.$message.success("操作成功");
          this.GetList();
        } else {
          this.$message.error(res.errorMsg);
        }
      });
    },
    async getCategoryTree() {
      let result = await category.getTree(false);
      if (result.errorCode == _this.$commons.RespCode.success) {
        _this.categoryList = result.data;
        result.data.forEach((item) => {
          if (item.id == 57) {
            _this.suaffArticleList.push(item.id);
            if (item.children) {
              item.children.forEach((item) => {
                _this.suaffArticleList.push(item.id);
              });
            }
          }
        });
      } else {
        _this.$message.error(result.errorMsg);
      }
    },
    onTypeSelect(selectKeys) {
      _this.search.categoryId = selectKeys[0];
      _this.searchList();
    },
    GetList() {
      //获取列表
      _this.isloading = true;
      let queryData = { ..._this.search };
      newsArticle
        .pageList(
          _this.list.pagination.current,
          _this.list.pagination.pageSize,
          queryData
        )
        .then((res) => {
          if (res.errorCode == _this.$commons.RespCode.success) {
            _this.list.datas = res.data.records;
            _this.list.pagination.total = Number(res.data.total);
          } else {
            _this.$message.error(res.errorMsg);
          }
          _this.isloading = false;
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    onPageChange(page) {
      _this.list.pagination.current = page.current;
      _this.list.pagination.pageSize = page.pageSize;
      this.GetList();
    },
    onRowChange(selectedRowKeys) {
      this.selectRows = selectedRowKeys;
    },
    cancelSelect() {
      _this.selectRows = [];
    },
    searchList() {
      _this.list.pagination.current = 1;
      _this.cancelSelect();
      _this.GetList();
    },
    /*审核查看 */
    showInfo(id) {
      this.addedit.visible = true;
      this.addedit.info = { id, selectTypeId: this.search.categoryId };
    },
    submit(id) {
      _this.isloading = true;
      newsArticle
        .flow({ id, publish: true })
        .then((res) => {
          if (res.errorCode == _this.$commons.RespCode.success) {
            _this.$message.success(res.errorMsg);
            _this.GetList();
          } else {
            _this.$message.error(res.errorMsg);
            _this.isloading = false;
          }
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    /** 批量提交 */
    batchSubmit() {
      this.$confirm({
        title: "确定要批量提交选择的文章吗?",
        okText: "确定",
        cancelText: "取消",
        onOk() {
          _this.submit(_this.selectRows.join());
        },
        onCancel() {},
      });
    },
    /**删除 */
    delInfo(id) {
      _this.isloading = true;
      newsArticle
        .removeByIds(id)
        .then((res) => {
          if (res.errorCode == _this.$commons.RespCode.success) {
            _this.$message.success(res.errorMsg);
            _this.selectRows = [];
            _this.GetList();
          } else {
            _this.$message.error(res.errorMsg);
            _this.isloading = false;
          }
        })
        .catch(() => {
          _this.isloading = false;
        });
    },
    /** 批量删除 */
    batchDelete() {
      this.$confirm({
        title: "确定要批量删除选择的文章吗?",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          _this.delInfo(_this.selectRows.join());
        },
        onCancel() {},
      });
    },
    save(f) {
      this.addedit.visible = false;
      if (f) this.GetList();
    },
    onDateChange(date, dateString) {
      _this.search.publishTimeStart = dateString[0];
      _this.search.publishTimeEnd = dateString[1];
    },
    showComment(id) {
      this.comment.id = id;
      this.comment.visible = true;
    },
  },
};
</script>

<style>
.left {
  float: left;
  width: 13%;
  margin-right: 10px;
  background: white;
  height: calc(100vh - 135px);
  padding: 10px;
}
.right {
  float: left;
  width: 85.8%;
  background: white;
}
</style>

